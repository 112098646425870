import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import telIcon from '../tel_icon.png';
import CardGroup from 'react-bootstrap/CardGroup';
import Button from 'react-bootstrap/Button';
import ensIcon from '../ens_icon.jpeg';


const Home = () => (
    <Container style={{ background: 'white',color: 'black' }} >
      <Row>
        <Col md = {{ span:6, offset: 3 }}>
          <img style={{ width: '270px', height: '80px' ,marginTop: '20px', marginBottom: '15px'}} src={telIcon} alt='tel' />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 6, offset: 3 }} >
          <p>
          Telprotocol is building a layer of decentralized communication networks. It can be used for dialing telephone,text message service, video calling .
          </p>
          <p>
          The architecture of telprotocol is a peer-to-peer protocol architecture. The core data is stored in the chain as NFT. 
          Telprotocol supports multiple chains and cross-chain transfers, and users can also use it on ethereum, arbitrum, optimism, zksync, etc .
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <CardGroup >
            <Card style={{ width: '18rem' }}>
              <Card.Body>
                <Card.Title>Telephone</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">Arbitrum</Card.Subtitle>
                <Card.Text>
                A telephone is a set of numbers, stored in the chain as NFT. Every telephone is unique. 
                The telephone is the foundation of the communication network. Build a decentralized communication network.
                </Card.Text>
                <Card.Link href="/minttel">MintTel</Card.Link>
              </Card.Body>
            </Card>
  
            <Card style={{ width: '18rem' }}>
                <Card.Body>
                  <Card.Title>Apps</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">Telephone</Card.Subtitle>
                  <Card.Text>
                  Telephone has multiple clients, including mobile phones, pc, and the web.
                  Two ways to use, deep login and light login.
                  Deep Login: You Must Import Your Ethereum account.
                  Light Login: use a password.
                  </Card.Text>
                  <Card.Link href="/apps">DialTel</Card.Link>
                </Card.Body>
            </Card>

          </CardGroup>

          <CardGroup style={{ marginTop: '20px' }} >
             <Card style={{ width: '18rem' }}>
                <Card.Body>
                  <a href='https://ens.domains/'>
                    <Card.Img variant="top" src={ensIcon} style={{ width: '50px', height: '50px' }} />
                  </a>
                  <Card.Text style={{marginTop: '15px' }}>

                  Ens.domains is connected to Telprotocol.
                  Use your ens.domains for call .
                  </Card.Text>
                  <Card.Link href="/ens" id='ens_enter_btn' >Enter</Card.Link>
                </Card.Body>
            </Card>

            {/* <Card style={{ width: '18rem' }}>
                <Card.Body>
                  <Card.Title>Lens</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted"><a href='https://www.lens.dev/' target='_blank' style={{ textDecorationLine: 'none' }} > lens.dev </a> </Card.Subtitle>
                  <Card.Text>
                  Lens.dev is connected to Telprotocol.
                  Use your Lens for call and SMS verification.
                  </Card.Text>
                  <Card.Link href="#" id='lens_enter_btn' >Enter</Card.Link>
                </Card.Body>
            </Card> */}

          </CardGroup>
        </Col>
      </Row>
  
      <Row style={{ marginTop: '30px', marginBottom: '30px' }}>
          <Col md={{ span: 6,offset: 3 }}>
            <>
              <Button href="https://mirror.xyz/0x296b01d75bE6c3a3484198130b1af1dC16442155/Lg2lZN-ONQe9dnmcnTTY_dbeb34G1qMhIdyXdmdEZEk">Learn</Button> 
              <Button style={{ marginLeft: '10px' }} href="https://github.com/telprotocol/telprotocol">Protocol</Button> 
              <Button style={{ marginLeft: '10px' }} href="/apps">Apps</Button> 
              <Button style={{ marginLeft: '10px' }} href="https://twitter.com/telprotocol">Twitter</Button>
              <Button style={{ marginLeft: '10px' }} href="https://etherscan.io/address/0xe348a8497a00ae9674cea417119a594b1706cf52">Donate</Button> 
            </>
          </Col>
      </Row>

      <Row style={{ textAlign: 'center', color: 'gray' }}>
      <Col>Copyright © 2023 Telprotocol</Col>
      </Row>
  
    </Container>
  )

  export default Home;