import ListGroup from 'react-bootstrap/ListGroup';

const Apps = () => (
    <ListGroup variant="flush" style={{ margin: '30px 30px 30px 30px' }}>

        <div><h5>Mobile Phone</h5></div>
        <ListGroup.Item> <a href='https://apps.apple.com/en/app/portsip-softphone/id426903818'> https://apps.apple.com/en/app/portsip </a> </ListGroup.Item>
        <ListGroup.Item>  <a href=''>  </a>  </ListGroup.Item>

        <div style={{ marginTop: '20px' }}><h5>PC</h5></div>
        <ListGroup.Item>  <a href='https://apps.apple.com/en/app/telephone/id406825478?l=zh&mt=12'> https://apps.apple.com/sg/app/telephone  </a> </ListGroup.Item>
        <ListGroup.Item>  <a href=''>  </a>  </ListGroup.Item>

        <div style={{ marginTop: '20px' }}><h5>Web</h5></div>
        <ListGroup.Item>  <a href=''>  </a>  </ListGroup.Item>
        <ListGroup.Item>  <a href=''>  </a>  </ListGroup.Item>
       
    </ListGroup>
)

export default Apps;