
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import  React,{Component, useState} from 'react';
import JsSIP from 'jssip';
import photo from '../photo.png';


function TestWeb3(){

    const [stateContact, setStateContact] = useState('');
    const [stateConnect, setStateConnect] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [userAgent, setUserAgent] = useState(null);
    const [callState, setCallState] = useState("idle");
    const [rotateImage, setRotateImage] = useState(false);
    const [contact, setContact] = useState('');
    // const [outgoingSession, setOutgoingSession] = useState('');

    var outgoingSession = null;
    var incomingSession = null;
    var currentSession = null;
    var localStream = null;

    const connectContact = async (contact,cryptoId) => {

      var sip_cryptoId = cryptoId;
      var ws_uri = 'wss://www.telprotocol-dev.club:7443/ws';
      var videoView = document.getElementById('videoView');
  
      var socket = new JsSIP.WebSocketInterface(ws_uri);
      var configuration = {
          sockets: [socket],
          outbound_proxy_set: ws_uri,
          uri: contact,
          password: sip_cryptoId,
          register: true,
          session_timers: false
      };
  
      const ua = new JsSIP.UA(configuration);
      setUserAgent(ua);
  
      ua.on('registered', function (data) {
        console.log(`registered ${data}`);
        setStateConnect('success');
      });
  
      ua.on('registrationFailed', function (data) {
          console.log(`registrationFailed ${data}`);
          setStateConnect('registrationFailed');
      });
  
      ua.on('registrationExpiring', function () {
        console.log('registrationExpiring');
      });
      
      
      ua.on('newRTCSession', function (data) {
          console.log('onNewRTCSession');
          //call incoming
          if (data.originator == 'remote') {
              console.info("answer the call------------");
              incomingSession = data.session;
              data.session.answer({
                  'mediaConstraints': {
                      'audio': true,
                      'video': false,
                      mandatory: {maxWidth: 640, maxHeight: 360}
                  }, 'mediaStream': localStream
              });
          } else {
              console.info("outgoingSession");
              outgoingSession = data.session;
              outgoingSession.on('connecting', function (data) {
                  console.info('onConnecting - ');
                  currentSession = outgoingSession;
                  outgoingSession = null;
                  setCallState("connecting");
              });
          }
          data.session.on('accepted', function (data) {
              console.info('onAccepted - ');
              if (data.originator == 'remote' ) {
                  currentSession = incomingSession;
                  incomingSession = null;
                  console.info("setCurrentSession - ");
              }
          });
          data.session.on('confirmed', function (data) {
              console.info('onConfirmed - ');
              if (data.originator == 'remote') {
                  currentSession = incomingSession;
                  incomingSession = null;
                  console.info("setCurrentSession - ");
                  setCallState("confirmed");
                  setShowModal(true);
                  setRotateImage(true);
              }
          });
          data.session.on('sdp', function (data) {
              console.info('onSDP, type - ');
          });
  
          data.session.on('progress', function (data) {
              console.info('onProgress - ');
              if (data.originator == 'remote') {
                  console.info('onProgress, response - ');
              }
          });
          data.session.on('peerconnection', function (data) {
              console.info('onPeerconnection - ');
              data.peerconnection.onaddstream = function (ev) {
                  console.info('onaddstream from remote ----------- ');
                  videoView.src = URL.createObjectURL(ev.stream);
              };
          });
      });
      
  
        ua.start();
      }; 

    // Register callbacks to desired call events
    var eventHandlers = {
      'progress': function (e) {
          console.log('call is in progress');
          setCallState("progress");
      },
      'failed': function (e) {
          console.log('call failed');
          setCallState("failed");
          setRotateImage(false);
      },
      'ended': function (e) {
          console.log('call ended : ');
          setCallState("ended");
          setShowModal(false);
          setRotateImage(false);
      },
      'confirmed': function (e) {
          console.log('call confirmed');
          setCallState("confirmed");
          setRotateImage(true);
      }
      };

      const callContact = (contact) => {
        var options = {
            'eventHandlers': eventHandlers,
            'mediaConstraints': {
                'audio': true, 'video': false,
                mandatory: {maxWidth: 640, maxHeight: 360}
            },
            'mediaStream': localStream,
            'pcConfig': {
            'iceServers': [
                {
                    'urls': ['stun:stun.l.google.com:19302','stun:stun1.l.google.com:19302'],
                }
            ]
        }
        };
        outgoingSession = userAgent.call(contact,options);
        setShowModal(true);
        setCallState('call is in progress');
    }


    const reg = () => {
      console.log('register----------->');
      userAgent.register();
    }

    const unReg = () => {
      console.log('unregister----------->');
      userAgent.unregister(true);
    }

    const hangup = () => {
      console.log('hangup----------->');
      userAgent.terminateSessions();
      setShowModal(false);
    }

    const onClickConnectAccount = async () => {

        const account_input = document.getElementById('connect_account_input');
        account_input.addEventListener('input', () => {
            account_input.value = account_input.value.trim();
        });

        const password_input = document.getElementById('connect_password_input');
        password_input.addEventListener('input', () => {
            password_input.value = password_input.value.trim();
        });

        console.log(`account_input.value: ${account_input.value} password_input.value: ${password_input.value}`);

        if(account_input.value == '' || password_input.value == ''){
          setStateConnect('Error: invalid account or password.');
          return;
        }

        connectContact(`sip:${account_input.value}@www.telprotocol-dev.club`,password_input.value);
    }

      const onClickContactCall = async () => {

        const account_input = document.getElementById('connect_account_input');
        const password_input = document.getElementById('connect_password_input');

          if(account_input == ''){
            setStateContact('Error: invalid account.')
            return;
          }

          if(password_input == ''){
            setStateContact('Error: invalid password.')
            return;
          }

          const contact_input = document.getElementById('contact_input');
          const input_contact = contact_input.value;
          setStateContact('');
          const input_contact_name =input_contact;
          if(input_contact_name.length < 4) {
            setStateContact('Error: check input')
          } else {
            console.log('call');
            setContact(input_contact_name);
            callContact(`sip:${input_contact_name}@www.telprotocol-dev.club`);
          }
        };

  return(
   <>
      <Container >
        <Row style={{ marginTop: '30px' }}>
            <Col style={{ textAlign: 'left' }}>
                <Button variant="outline-primary" href='/'>Home</Button>{' '}
                <Button variant="outline-primary" style={{ marginLeft: '15px' }} href='/apps' >Apps</Button>{' '}
            </Col>
        </Row>

        <Row>

            <Col md={{ span: 3, offset: 6 }} style={{ margin: '0px auto' }}>
                <Card style={{ width: '18rem' }}>
                <Card.Body>
                    <Card.Title></Card.Title>
                    <Card.Text>
                    </Card.Text>

                    <div className="form-floating">
                        <input type="text" className="form-control" id='connect_account_input' placeholder="00000" style={{ width: '240px' }} />
                        <label htmlFor="floatingInput">my account</label>
                    </div>
                    <div className="form-floating">
                        <input type="text" className="form-control" id='connect_password_input' placeholder="00000" style={{ width: '240px' }} />
                        <label htmlFor="floatingInput">my password</label>
                    </div>

                    <label id='connect_state_label' style={{ color: 'red', marginLeft: '10px', fontSize: '14px' }} > {stateConnect} </label>

                    <div className='text-center' style={{ marginTop: '15px' }}>
                        <Button style={{ marginLeft: '10px' }} id='connect_account_btn' onClick={onClickConnectAccount} > Connect </Button> 
                    </div>

                </Card.Body>
                </Card>
            </Col>

            <Col md={{ span: 3, offset: 6 }} style={{ margin: '0px auto' }}>
                <Card style={{ width: '18rem' }}>
                <Card.Body>
                    <Card.Title></Card.Title>
                    <Card.Text>
                    </Card.Text>
                    <div className="form-floating">
                        <input type="text" className="form-control" id='contact_input' placeholder="00000" style={{ width: '240px' }} />
                        <label htmlFor="floatingInput">contact</label>
                    </div>

                    <label id='contact_state_label' style={{ color: 'red', marginLeft: '10px', fontSize: '14px' }} > {stateContact} </label>

                    <div className='text-center' style={{ marginTop: '15px' }}>
                        <Button style={{ marginLeft: '10px' }} id='contact_call_btn' onClick={onClickContactCall} > Call </Button> 
                    </div>
                    
                </Card.Body>
                </Card>
            </Col>

        </Row>

        <Row style={{ marginTop: '30px' }}>
            <Col md={{ span: 3, offset: 6 }} style={{ margin: '0px auto' }}>
                <Button style={{ marginLeft: '10px' }} onClick={unReg} > unReg </Button>
                <Button style={{ marginLeft: '10px' }} onClick={reg} > reg </Button>
            </Col>
        </Row>


        <Row style={{ textAlign: 'center', color: 'gray' , marginTop: '60px'}}>
          <Col>Copyright © 2023 Telprotocol</Col>
        </Row>

        <Row>
        <video id="videoView" width="420px" height="320px" autoPlay></video>
        </Row>

    </Container>
     {showModal && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 999,
          }}
        >
          <div
            style={{
              width: 300,
              height: 500,
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 10,
            }}
          >
            <h3 style={{ color: 'black', marginTop: '15px' }} > {contact} </h3>
            <div style={{ marginTop: '50px'}} >
                <img src={photo} 
                    style={{ 
                        width: 100,
                        height: 100,
                        borderRadius: '50%',
                        animation: `${rotateImage ? 'rotation 6s infinite linear' : ''}`, // added conditional rendering of animation
                    }}
                 />
                 {/* CSS animation for rotating the image */}
                <style>
                {rotateImage && // added conditional rendering of style
                    `@keyframes rotation {
                    from {
                        transform: rotate(0deg);
                    }
                    to {
                        transform: rotate(359deg);
                    }
                    }`
                }
                </style>
            </div>

            <div>
                <label id='call_state_label' style={{ color: 'gray', fontSize: '14px', marginTop: '130px' }} > {callState} </label>
            </div>
            
            <button
              style={{
                backgroundColor: 'blue',
                color: 'white',
                marginTop: 'auto',
                width: '150px',
                height: '50px',
                borderRadius: 10,
                border: 'none',
                marginBottom: '20px',
              }}
              onClick={hangup}
            >
              Hang Up
            </button>
          </div>
        </div>
      )}
   </>
  );
}

export default TestWeb3;

