
const {whitelist} = require('./whitelist_data');
const { MerkleTree } = require('merkletreejs');
const { ethers } = require('ethers');
const { keccak256 } = ethers.utils;
var Buffer = require('buffer/').Buffer  // note: the trailing slash is important!


const padBuffer = (addr) => {
    return Buffer.from(addr.substr(2).padStart(32*2, 0), 'hex')
}


function verifyWhitelist(_account, contract){

    const leaves = whitelist().map(account => padBuffer(account.toLowerCase()))
    const tree = new MerkleTree(leaves, keccak256, { sort: true })
    const root = tree.getHexRoot()

    const leaf = padBuffer(_account.toLowerCase());

    const proof = tree.getHexProof(leaf)

    // console.log(`root: ${root} ,account: ${_account}, leaf: ${leaf}, proof: ${proof} `);
    const l_result = tree.verify(proof,leaf,root);
    // console.log(`local-verity-result: ${l_result} `);
    return {l_result, proof};
}

export {verifyWhitelist};
