
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import MetaMaskOnboarding from '@metamask/onboarding'
import  React,{Component, useState} from 'react';
import { ethers } from 'ethers';
import {getEnsVerity} from './client';
import ens_name_Icon from '../ens_name_Icon.png';
import telIcon from '../tel_icon.png';
import ensIcon from '../ens_icon.jpeg';
import pc_ens_Icon from '../pc_ens.png';
import app_download_Icon from '../app_download.png';
import portsip from '../portsip.png';
import { Button as Button_ant } from 'antd'


function Ens(){

    const walletName = 'connect wallet';
    const [c_account, setAccount] = useState(walletName);
    const [c_address, setAddress] = useState('');
    const [c_chainId, setChainId] = useState('');
    const [stateLogin, setStateLogin] = useState('');
    const [statePassword, setStatePassword] = useState('');
    const [disabledConnetctBtn, setDisabledConnectBtn] = useState(false);
    const [loading, setLoadnig] = useState(false)

    const forwarderOrigin = 'https://metamask.io/';
    const onboarding = new MetaMaskOnboarding({forwarderOrigin});
    const { ethereum } = window;
    const isMetaMaskInstalled = () => {
        return Boolean(ethereum && ethereum.isMetaMask);
    }


    const onClickInstall = () => {
      setAccount('installing');
      // setDisabledConnectBtn(true);
      onboarding.startOnboarding();
    };

    const onClickConnect = async () => {
      setStateLogin('');
      const ethersProvider = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const password_input = document.getElementById('password_input');
      password_input.addEventListener('input', () => {
        password_input.value = password_input.value.trim();
      });

      try {
        const newAccounts = await ethereum.request({method: 'eth_requestAccounts'});
        const chainId = await ethereum.request({method: 'eth_chainId'});
        setChainId(chainId);
        const address = newAccounts[0];
        setAddress(address);
        var ensName = await ethersProvider.lookupAddress(address);
        const ensNameStr = ensName == null ? "error ensname" : ensName;
        setAccount(ensNameStr);
        if(ensNameStr !== "error ensname"){
          setDisabledConnectBtn(true);
        }else{
          setStateLogin('Error: the address is not associated with the ENS domain name.');
        }
      }catch(error){
        console.error(error);
        setStateLogin('error');
      }
    };

      const onClickPasswordSet = async () => {

          setStatePassword('');
          setStateLogin('');

          if(c_account == '' || c_account == walletName || c_account == 'error ensname'){
            setStatePassword('Error: invalid domain name,reconnect the wallet .')
            return;
          }

          const password_input = document.getElementById('password_input');
          const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/;
          if(reg.test(password_input.value)){
            setLoadnig(true);
            signVerity(c_account,c_address,parseInt(c_chainId,16),crypto.randomUUID(),password_input.value);
          }else{
            setStatePassword('Error: password, check input');
            return;
          }
        };
        
        const signVerity = async (tel_user,tel_addr,tel_chainId,tel_nonce,tel_code) => {
          const tel_user_name = tel_user;
          const typedData = {
            types: {
              EIP712Domain: [],
              Telprotocol: [
                { name: 'name', type: 'string' },
                { name: 'version', type: 'string' },
                { name: 'chainId', type: 'string' },
                { name: 'ensname', type: 'string' },
                { name: 'address', type: 'address'},
                { name: 'code', type: 'string' },
                { name: 'nonce', type: 'string' },
                { name: 'content', type: 'string' },
              ],
            },
            primaryType: 'Telprotocol',
            domain: {},
            message: {
                name: 'Telprotocol',
                version: '0.1',
                chainId: tel_chainId,
                ensname: tel_user_name,
                address: tel_addr,
                code: tel_code,
                nonce: tel_nonce,
                content: ` Connect `,
            },
          }

          try {
            const result = await ethereum.request({
              method: 'eth_signTypedData_v4',
              params: [tel_addr, JSON.stringify(typedData)],
            })

            getEnsVerity({
              message: typedData,
              signerAddress: tel_addr,
              signature: result,
            }, (result) => {
              if(result.state == 10){
                setStatePassword('success');
                setLoadnig(false);
              }else{
                setStatePassword('failed');
                setLoadnig(false);
              }
            });
          } catch (err) {
            console.error(err)
            setStatePassword(`Error: ${err.message}`);
            setLoadnig(false);
          }
        }

  return(
   <>
      <Container >
        <Row style={{ marginTop: '0px',backgroundColor:'rgb(255,255,255)'  }}>
            {/* <Col style={{ textAlign: 'left' }}>
                <Button variant="outline-primary" href='/'>Home</Button>{' '}
                <Button variant="outline-primary" style={{ marginLeft: '15px' }} href='/apps' >Apps</Button>{' '}
            </Col> */}
            <Col>
              <a href='https://www.telprotocol.com/'>
                <img style={{ width: '270px', height: '80px' ,marginTop: '20px', marginBottom: '15px'}} src={telIcon} alt='tel' />
              </a>
            </Col>
            <Col style={{ textAlign: 'right',marginTop: '45px',marginBottom: '30px' }}>
                <label id='connect_state_label' style={{ color: 'red', marginRight: '10px', fontSize: '14px' }} > {stateLogin} </label>
                <Button variant="outline-primary" id="connectButton" style={{ width: '180px', overflow: 'hidden',whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} disabled={disabledConnetctBtn} onClick={ isMetaMaskInstalled() ? onClickConnect : onClickInstall }  > { c_account } </Button>
            </Col>
            {/* <a href='https://ens.domains/'>
              <Card.Img variant="top" src={ens_name_Icon} style={{ width: '150px', height: '83px' }} />
            </a> */}

        </Row>

        <Row style={{ backgroundColor: 'rgb(248,248,248)' }}>

            <Col md={{ offset: 1}} style={{ }}>
              <Card border="light"  style={{ width: '30rem' ,marginTop: '30px', backgroundColor: 'rgb(248,248,248)'}}>
              <Card.Body>
                <Card.Title>Ens.Domains</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">Your web3 username</Card.Subtitle>
                  <Card.Text style={{marginTop: '15px' }}>
                  Ens.domains is connected to Telprotocol.
                  Use your ens.domains for call .
                  </Card.Text>
                  <a href='https://ens.domains/'>
                    <Card.Img variant="top" src={ensIcon} style={{ width: '50px', height: '50px' }} />
                  </a>
                </Card.Body> 
              </Card>
            </Col>

            <Col md={{}} style={{ margin: '0px auto' }}>
                <Card style={{ width: '30rem' ,marginTop: '20px',backgroundColor: 'rgb(248,248,248)' }}>
                <Card.Body>
                    <Card.Text>
                    Set a password for your Ens.Domains and use it in the app. At least 8-16 characters, at least 1 uppercase letter, 1 lowercase letter and 1 number.
                    </Card.Text>
                    <div className="form-floating" >
                        <input type="password" className="form-control" id='password_input' placeholder="00000" style={{ width: '240px' }}  />
                        <label htmlFor="floatingInput">password</label>
                    </div>
                    <label id='password_state_label' style={{ color: 'red', marginLeft: '10px', fontSize: '14px' }} > {statePassword} </label>
                    <div className='text-center' style={{ marginTop: '15px'}}>
                        {/* <Button style={{ marginLeft: '10px' }} id='password_set_btn' onClick={onClickPasswordSet} > Set </Button>  */}
                        <Button_ant id='password_set_btn' onClick={onClickPasswordSet} type='primary' loading={loading}>Set</Button_ant>
                    </div>
                </Card.Body>
                </Card>
            </Col>

        </Row>

        <Row style={{ }}>

          <Col md={{ offset: 1}} style={{ }}>
            <img style={{ width: '420px', height: '250px' ,marginTop: '20px', marginBottom: '15px', borderRadius:'10px'}} src={pc_ens_Icon} alt='Telprotocol' />
          </Col>

          <Col md={{ }} style={{ }}>
              <Card border="light"  style={{ width: '30rem' ,marginTop: '30px'}}>
              <Card.Body>
                <Card.Title>Telephone</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">Light Login</Card.Subtitle>
                  <Card.Text style={{marginTop: '15px' }}>
                  Telephone is an App for Mac.
                  Telephone supports a light login mode for Telprotocol.
                  </Card.Text>
                  <a href='https://apps.apple.com/en/app/telephone/id406825478?l=zh&mt=12' target='_blank'>
                    <Card.Img variant="top" src={app_download_Icon} style={{ width: '173px', height: '51px' }} />
                  </a>
                </Card.Body> 
              </Card>
            </Col>

        </Row>

        <Row style={{ backgroundColor: 'rgb(248,248,248)' }}>

          <Col md={{ offset: 1}} style={{ }}>
            <Card border="light"  style={{ width: '30rem' ,marginTop: '30px', backgroundColor: 'rgb(248,248,248)'}}>
            <Card.Body>
              <Card.Title>Portsip</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Light Login</Card.Subtitle>
                <Card.Text style={{marginTop: '15px' }}>
                Portsip is an App for iphone. 
                Portsip supports a light login mode for Telprotocol.
                </Card.Text>
                <a href='https://apps.apple.com/en/app/portsip-softphone/id426903818' target='_blank'>
                    <Card.Img variant="top" src={app_download_Icon} style={{ width: '173px', height: '51px' }} />
                  </a>
              </Card.Body> 
            </Card>
          </Col>

          <Col md={{ offset: 1}} style={{ }}>
            <img style={{ width: '420px', height: '320px' ,marginTop: '20px', marginBottom: '15px', borderRadius:'10px'}} src={portsip} alt='Telprotocol' />
          </Col>

        </Row>


        <Row style={{ textAlign: 'center', color: 'gray' , marginTop: '60px', marginBottom: '30px'}}>
          <Col>Copyright © 2023 Telprotocol</Col>
        </Row>

    </Container>
   </>
  );
}

export default Ens;

