

function getConnectState(success){
    return fetch('/proxy_tel_server/netstate',{
        headers: {
            Accept: 'application/json',
        },
    })
    .then(checkStatus)
    .then(parseJSON)
    .then(success);
}

function getVerity(data,success){
    return fetch('/proxy_tel_server/verity',{
        method: 'post',
        body: JSON.stringify(data),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
    .then(checkStatus)
    .then(parseJSON)
    .then(success);
}

function getEnsVerity(data,success){
    return fetch('/proxy_tel_server/ensverity',{
        method: 'post',
        body: JSON.stringify(data),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
    .then(checkStatus)
    .then(parseJSON)
    .then(success);
}


function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      const error = new Error(`HTTP Error ${response.statusText}`);
      error.status = response.statusText;
      error.response = response;
    //   console.log(error);
      throw error;
    }
}

function parseJSON(response) {
    return response.json();
}

export {
    getConnectState,
    getVerity,
    getEnsVerity,
};