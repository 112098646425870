
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import './MintTel.css';
import MetaMaskOnboarding from '@metamask/onboarding'
import  React,{Component} from 'react';
import { contractJson } from './constants';
import { ethers } from 'ethers';
import {getConnectState, getVerity} from './client';
import { verifyWhitelist } from './whitelist';


const MintTelView = () => (
    <Container >
        <Row style={{ marginTop: '30px' }}>
            <Col style={{ textAlign: 'left' }}>
                <Button variant="outline-primary" href='/'>Home</Button>{' '}
                {/* <Button variant="outline-primary" style={{ marginLeft: '15px' }} href='/apps' >Apps</Button>{' '} */}
                <Button variant="outline-primary" style={{ marginLeft: '15px' }} id='app_btn' href='/apps' >Apps</Button>{' '}
            </Col>
            <Col style={{ textAlign: 'right', marginBottom: '30px' }}>
                <Button variant="outline-primary" id="connectButton" style={{ width: '180px', overflow: 'hidden',whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} >connect wallet</Button>{' '}
            </Col>
        </Row>

        {/*   
              Tips for production environments
          mint telephone, the telephone,at least 5-11 digits, for example: 00001.
          The telephone will be charged 0.02eth per year. Calls are free.

              Tips for the test environment
          mint telephone, the telephone,at least 5-11 digits, for example: 00001.
          It is currently a testing network, and only whitelisted users can participate.
        */}

        <Row>
            <Col md={{ span: 4, offset: 6 }} style={{ margin: '0px auto' }}>
                <Card style={{ width: '18rem' }}>
                <Card.Body>
                    <Card.Title>Mint</Card.Title>
                    <Card.Text>
                    mint telephone, the telephone,at least 5-11 digits, for example: 00001.
                    It is currently a testing network, and only whitelisted users can participate.
                    </Card.Text>
                    <div className="form-floating">
                        <input type="number" className="form-control" id='tel_input' placeholder="00000" style={{ width: '240px' }} />
                        <label htmlFor="floatingInput">telephone</label>
                    </div>
                    <label id='tel_state_label' style={{ color: 'red', marginLeft: '10px', fontSize: '14px' }} ></label>
                    <div className='text-center' style={{ marginTop: '15px' }}>
                        <Button style={{ marginLeft: '10px' }} id='tel_mint_btn' >Mint</Button> 
                    </div>
                </Card.Body>
                </Card>
            </Col>
        </Row>

        {/* 
            At least 8-16 characters, at least a majuscule, a lowercase letter, and a number, and any other characters:
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\s\S]{8,16}$/ 
             
        */}

        <Row>
            <Col md={{ span: 4, offset: 6 }} style={{ margin: '0px auto' }}>
                <Card style={{ width: '18rem', marginTop: '15px'}}>
                <Card.Body>
                    <Card.Title>Password</Card.Title>
                    <Card.Text>
                    Set a password for your telephone and use it in the app.
                    At least 8-16 characters, at least 1 uppercase letter, 1 lowercase letter and 1 number.
                    </Card.Text>
                    <div className="form-floating">
                        <input type="number" className="form-control" id="password_tel_input" placeholder="00000" style={{ width: '240px' }} />
                        <label htmlFor="floatingInput">telephone</label>
                    </div>
                    <div className="form-floating" style={{ marginTop: '5px' }}>
                        <input type="password" className="form-control" id="password_text_input" placeholder="password" style={{ width: '240px' }} />
                        <label htmlFor="floatingInput">password</label>
                    </div>
                    <label id='password_state_label' style={{ color: 'red', marginLeft: '10px', fontSize: '14px' }} ></label>
                    <div className='text-center' style={{ marginTop: '15px' }}>
                        <Button style={{ marginLeft: '10px' }} id='password_set_btn' >Set</Button> 
                    </div>
                </Card.Body>
                </Card>
            </Col>
        </Row>

    </Container>
)

 
function dealTextShow(content){
    if(content.length > 15){
        return content.substr(0,6) + '...' + content.substr(content.length-6,6);
    }
    return content;
}

class MintTel extends Component {

    state = {
      c_account: '',
      c_chainid: '',
    }

    componentDidMount(){
        // console.log('componentDidMount');
        this.initialize();
    }

    initialize = async () => {
        const forwarderOrigin = 'https://metamask.io/'
        const onboarding = new MetaMaskOnboarding({forwarderOrigin})
        const { ethereum } = window;
        const isPublicMint = false;
        const isMetaMaskInstalled = () => {
        return Boolean(ethereum && ethereum.isMetaMask);
        }
        const onboardButton = document.getElementById('connectButton')

        const appBtn = document.getElementById('app_btn');
        appBtn.onclick = () => {
          // console.log('app click');
          getConnectState((result)=>{
            // console.log(result.content);
          });
        }

        const onClickInstall = () => {
            onboardButton.innerText = 'installing';
            onboardButton.disabled = true;
            onboarding.startOnboarding();
          };
        const onClickConnect = async () => {
            try {
              const newAccounts = await ethereum.request({method: 'eth_requestAccounts'});
              const chainId = await ethereum.request({method: 'eth_chainId'});
              const stateData = Object.assign({},this.state);
              stateData.c_account = newAccounts[0];
              stateData.c_chainid = parseInt(chainId,16);
              this.setState(stateData,() => {
                // console.log(`state： ${this.state.c_account} account: ${newAccounts[0]} chainid: ${this.state.c_chainid}`)
                onboardButton.innerText = dealTextShow(this.state.c_account);
              });

            }catch(error){
              console.error(error);
            }
          };

        const MetaMaskClientCheck = () => {
            if(!isMetaMaskInstalled()){
              onboardButton.innerText = "install metamask";
              onboardButton.onclick = onClickInstall;
              onboardButton.disabled = false;
            }else{
              onboardButton.innerText = 'connect wallet';
              onboardButton.onclick = onClickConnect;
              onboardButton.disabled = false;
            }
          };
        MetaMaskClientCheck();

        const ethersProvider = new ethers.providers.Web3Provider(window.ethereum, 'any');
        const telContract = new ethers.Contract(
          contractJson.tel_Addr,
          contractJson.telContractAbi,
          ethersProvider.getSigner(),
        );


        const tel_input = document.getElementById('tel_input');
        const tel_mint = document.getElementById('tel_mint_btn');
        const tel_state_label = document.getElementById('tel_state_label');
        const password_state_label = document.getElementById('password_state_label');
        const password_tel_input = document.getElementById('password_tel_input');
        const password_text_input = document.getElementById('password_text_input');

        const autoTelInput = () => {
          password_tel_input.value = tel_input.value;
        }

        const onClickMintTel = async () => {
          const input_tel = tel_input.value.toString();
          tel_state_label.innerText = ''
          // console.log(input_tel);
          if (input_tel.includes('.') ||
              input_tel.length > 11 ||
              input_tel.length < 5
            ) {
            tel_state_label.innerText = 'Error: check input'
          } else {
            const isClaimed = await telContract.isClaimed(input_tel);
            if(isClaimed){
              tel_state_label.innerText = 'Error: already claimed'
            }else{
              const {l_result, proof} = verifyWhitelist(this.state.c_account, telContract);
              tel_state_label.innerText = ''
              if(isPublicMint){
                const result_mint = await telContract.mint(input_tel,1,{
                  // value: ethers.utils.parseEther('0.02'),
                });
                // console.log(`result_mint: ${result_mint}`);
                autoTelInput();
              }else{
                if(l_result){
                  tel_state_label.innerText = '';
                  const result_mint = await telContract.mintFromWhitelist(input_tel,1,proof,{
                    // value: ethers.utils.parseEther('0.02'),
                  });
                  // console.log(`result_mint: ${result_mint}`);
                  autoTelInput();
                }else{
                  tel_state_label.innerText = 'Error: not whitelisted .'
                }
              }
            }
          }
        };
        tel_mint.onclick = onClickMintTel;
        const password_set_btn = document.getElementById('password_set_btn');
        
        const signVerity = async (tel_user,tel_id,tel_code,tel_tel) => {
          const typedData = {
            types: {
              EIP712Domain: [
                { name: 'name', type: 'string' },
                { name: 'version', type: 'string' },
                { name: 'chainId', type: 'string' },
                { name: 'verifyingContract', type: 'address' },
              ],
              Tel: [
                { name: 'user', type: 'address' },
                { name: 'tel', type: 'string' },
                { name: 'id', type: 'string' },
                { name: 'code', type: 'string'},
                { name: 'nonce', type: 'string' },
                { name: 'content', type: 'string' },
              ],
            },
            primaryType: 'Tel',
            domain: {
              name: 'Ether Telephone',
              version: '1',
              chainId: this.state.c_chainid,
              verifyingContract: contractJson.tel_Addr,
            },
            message: {
              user: tel_user,
              tel: tel_tel,
              id: tel_id.toString(),
              code: tel_code,
              nonce: crypto.randomUUID(),
              content: ` Light login can be used for user . `,
            },
          }

          try {
            const result = await ethereum.request({
              method: 'eth_signTypedData_v4',
              params: [tel_user, JSON.stringify(typedData)],
            })
            // console.log(`sign: ${JSON.stringify(result)}`);

            getVerity({
              message: typedData,
              signerAddress: tel_user,
              signature: result,
            }, (result) => {
              // console.log(`response: ${ JSON.stringify(result) }`);
              password_state_label.innerText = result.des
            });
          } catch (err) {
            console.error(err)
          }
        }

        const onClickSetPassword = async () => {
          const password_tel = password_tel_input.value.toString();
          // console.log(`random: ${ crypto.randomUUID()  } `);
          password_state_label.innerText = '';
          // console.log(password_tel);
          if (password_tel.includes('.') ||
              password_tel.length > 11 ||
              password_tel.length < 5
            ) {
            password_state_label.innerText = 'Error: tel, check input'
          } else {
            const isClaimed = await telContract.isClaimed(password_tel);
            if(!isClaimed){
              password_state_label.innerText = 'Error: tel, not exist'
            }else{
              const tel_id = await telContract.getTokenIDFromTel(password_tel);
              // console.log(`tel_id: ${tel_id}`);
              const tel_owner = await telContract.ownerOf(tel_id);
              const current_user = this.state.c_account;
              // console.log(`current owner: ${tel_owner}  current_user: ${current_user}`);
              // console.log(`current owner: ${tel_owner.toLowerCase()}  current_user: ${current_user.toLowerCase()}`);
              if(current_user.toLowerCase() === tel_owner.toLowerCase()){
                const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/;
                if(reg.test(password_text_input.value)){
                  signVerity(current_user,tel_id,password_text_input.value,password_tel);
                }else{
                  password_state_label.innerText = 'Error: password, check input'
                }

              }else{
                password_state_label.innerText = 'Error: tel, not a valid owner'
              }
            }
          }

        }
        password_set_btn.onclick = onClickSetPassword;

    }


    render(){
        return(
            <MintTelView />
        ); 
    }
}

export default MintTel;
