const whitelist = () => {
    return [
        "0x7147498b386cB9A444c89E28605cec0116153F44",
        "0xDea3D5d7D0DA6160E7831518FF3b40166F498441",
        "0xD7cA27B6175bd0617D31978f3574f4f26eA49395",
        "0xabe763E47747701E45baD78Aed68e10604289D5B",
        "0xda825e240248DEDe5aE5770fcbe28050b000B629",
        "0xE08d30BAf9b7D225C0239406Fe57cC13aBEAa791",
        "0x296b01d75bE6c3a3484198130b1af1dC16442155",
        "0xc1a31523ae4F9121Ca2422F2347547b6ed12edEF",
        "0x28C173B8F20488eEF1b0f48Df8453A2f59C38337",
        "0x991162F5177Aa690888057f9aeC7816AdD061135",
        "0x3062796Fb01De6b5E4965200c6061F708FfDbB83",
        "0x78fC16E8521EEaF584C11858A8C81199dbEe6BAF",
        "0x587c40337AaFBEECafafF0e2e37f94e9EB6a18aa",
        "0x24254994082b071C9EF8648a9d3FFB1E33755e73",
        "0xf2c04B6E5b3b052a81cFB9668F598F03a98D93e8",
        "0x2612b77E5ee1b5feeDdD5eC08731749bC2217F54",
        "0xE114dD0469a6ae63CCf34c2Ac4146CEf49348A9A",
        "0xE72D6a1Bc41ca175C80a471Ce7d0268D3232BC48",
        "0x9633E637E99d9B84A678a9A93C42f3082855db35",
        "0xffd4142647E5D0E52e5659052F1b3eA5A19AB065",
        "0x69Defd8924501af6380c2b892CE9BDD868fF6E5A",
        "0x79A597Ac744D50e0a00575Ae55e2B48653e495ff",
        "0xb889076b00f40F16D90b0130D177052E43B48d93",
        "0x2F7448B62134e52C2f46454d0089Ae21B5248805",
        "0xe1287b451731c5E8625410b59fdA8E9c8C240098",
        "0x4939B09447f065C6444174dEdcc7E6fc6f9c00Ef",
        "0xdeF5F34e02b09EDa82c3Fb02550647226751c1A0",
        "0x491B3069BEa3c4d9a61ddd1bc78Ef5a7A91D1287",
        "0x29C46f38D6BeC53C70cc3A019bfEE96A6E3f740f",
        "0x841eB179CBcF63f73ae75EC34d1ab3d6D91FF2ea",
        "0x157D6fC7F3ea2bcbcdc5C8C0d4C5AD85EF952b93",
        "0x01C0d45E2DB328DcCa05194DA9B9D836c88Bc1E0",
        "0xA4fF6Ce148112D02D898A15f1184B52CE750434a",
        "0x2EB08B430Ce3f881865b7C6886eFC61D5aaB45c3",
        "0xa7E3DB606d416f7Ca4275EB1A1337EB9308bE89F",
        "0x3f91D40dBA178A6206F4e4B4047973490338c92D",
        "0x569580f39ECA32417C807578273CB081672C704f",
        "0x3Fe4252fEEcf0CbdDBE569CFCE02FD6e35AB7929",
        "0x94b25D56A6195b6Fd3C552d14d4Bc25EF35AA972",
        "0x9A00781946447D4d9c5a7bc8C46C47bD31797165",
        "0x0C409B0FDf1c9D395d28a11EE04f9EEA6E61957F",
        "0x120Dde6a1cCCa2DB5180C6B16176a06CC46ff54A",
        "0xD394868533c04818b9c91F894C7e8be7f13ce9A9",
        "0x5A343b09f43dc074A8e1Bc3A42d70C5087c08Fc4",
        "0x41B8708151D6e936122e55F6325740E6A8F9a391",
        "0xD41077DcE1BbAaD3a913EA2F2F0E2ddd18027ab9",
        "0xd0778ff5adeaaa6cb3d1ef98b899bc386bff00b7",
        "0xaF124Ed6Feaa653Ce42a85fE0a85bd1e8852dd7b",
        "0xD3473F77654F0D4dCDb741cf8d5835be0A3E5958",
        "0x199A0784110121D9718aBaF0bc67a482D285e15c",
        "0x92dAD04BEDd8B6F26042C5eC2CbF24423716Eb66",
        "0x08Dbe5bA9e385eACA1e33996227923C9B5204e58",
        "0x510D8800A6aEB4de8A7c4e2a67c6dce500505379",
        "0x728671CE3F48B398c0B36924ADCBC37d67fd0741",
        "0x45DC1a234C02F9f31B57a6516e1445d61081596c",
        "0xc9C62e274a151de798F69a792c559e75622B3e0b",
        "0x311025CD6bD7f42641d35d0871559e0a820F7Fd3",
        "0xf0723d25Fe867dE1692F5715c0f1dd54bA3eC3D8",
        "0x0a7a667e150ac5f93cb2870e790d9c4d1d7585e0",
        "0xbC3678f66676442b725dcA935C5D571f5Cd8aB8C",
        "0x1abFcd17791245467CB99F7EA32De020AC4F6681",
        "0xB0a65C2725BAb704BB46702B2F08428CE37B268e",
        "0x290C502FA2826D2B76474e8f19c7CDd28afe5076",
        "0x2EB08B430Ce3f881865b7C6886eFC61D5aaB45c3",
        "0x3f91D40dBA178A6206F4e4B4047973490338c92D",
        "0xe1287b451731c5E8625410b59fdA8E9c8C240098",
        "0x0e5C171F1D6dB772812938Ab9d2142e3646e1198",
        "0x4939B09447f065C6444174dEdcc7E6fc6f9c00Ef",
        "0xA9211529BcB4B708C12477bDf982D3050c90E188",
        "0xdeF5F34e02b09EDa82c3Fb02550647226751c1A0",
        "0x491B3069BEa3c4d9a61ddd1bc78Ef5a7A91D1287",
        "0x29C46f38D6BeC53C70cc3A019bfEE96A6E3f740f",
        "0x841eB179CBcF63f73ae75EC34d1ab3d6D91FF2ea",
        "0x157D6fC7F3ea2bcbcdc5C8C0d4C5AD85EF952b93",
        "0x01C0d45E2DB328DcCa05194DA9B9D836c88Bc1E0",
        "0xA3FBa561AE5E871161A650606Cc4a9cb1E1736D5",
        "0xA4fF6Ce148112D02D898A15f1184B52CE750434a",
        "0xaa70eb0F738078e3D55c76783BCEA4Eb503fa51f",
        "0x7ac5645513297E495b28eB2C00CF0f4F53963EC9",
        "0x5fD026bf347C977051Bd20CaDDD662Ef85dE7F2c",
        "0x44Ac00A5e5bB03F7A724A5fbA1f9F59776BD4071",
        "0x7B804b0B4aa31B7efb39833676fF00633299D2d3",
        "0x658EDB72fC0905c37Df975e7140D6B2c52907515",
        "0x33B2CB7Ef789eF5069eA0A6cDBA0aD14D1F8805b",
        "0xE72D6a1Bc41ca175C80a471Ce7d0268D3232BC48",
        "0x53848A121eeC1b2359258883Bf94A17FB54B49a5",
        "0x1B2CcC6Ccf3A9b448991abdEcD6EE6D08A0FB6D6",
        "0xff9DcdAC4Ac0521123733b20fD17546e1d7aF888",
        "0x09E777A25caD1e94B6eABb98865421dE17CaC1c9",
        "0xD7Ef77A88FED65a38CC74f7787706f4FcE678176",
        "0x797434adB7A2eBC2B31A36A83Ed81BfdDF17e2C8",
        "0x1B0dCF4DBe4d705bC9F9D9482a721726859d2cD2",
        "0x06dfDC4B753a7179c5AcE6D537ae1dd48162a0ef",
        "0x87D0Ddc0123dB159d7b38Be65FC4998b1aFCa3Eb",
        "0x78b387b42D8859dD10C0C95ceC377be85CF9012d",
        "0xFaA9dd71625979ae12Ca8Cb8B88b36E7a162a500",
        "0x9f5c1A714478CA3D99bE0728d282ed212A5f48Cc"
    ];
} 
module.exports = {whitelist};

