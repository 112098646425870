import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import './App.css';
import Home from './components/Home.js';
import MintTel from "./components/MintTel";
import DialTel from "./components/DialTel";
import NotPage from "./components/NotPage";
import Apps from "./components/Apps";
import Ens from "./components/Ens";
import TestWeb3 from "./components/TestWeb3";


function App() {
  // console.log('App start ...');
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="minttel" element={<MintTel />} />
        <Route path="dialtel" element={<DialTel />} />
        <Route path="apps" element={<Apps />} />
        <Route path="ens" element={<Ens />} />
        <Route path="testweb3" element={<TestWeb3 />} />
        <Route path="*" element = { <NotPage /> }/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;